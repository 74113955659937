<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 text-center">
                <div class="header" :class="[$i18n.locale == 'en' ? 'start' : 'end']">
                    <h1>{{ $t('dashboard.reports.title') }} <span>( {{ reportsCount }} )</span></h1>
                    <button class="btn btn-add" @click.prevent="$router.push(`/${$i18n.locale}/!/add-report`)"><img
                            src="../../../assets/icons/plus-w.png" alt="add"></button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center d-flex align-items-end"
            :class="[$i18n.locale == 'en' ? '' : 'flex-row-reverse text-end']">
            <div class="col-12 col-sm-12 col-lg-4 col-xl-4 form-group mb-2 mb-sm-0">
                <label for="search">{{ $t('dashboard.reports.search') }}</label>
                <input type="text" class="form-control" id="search" v-model="search"
                    :class="[$i18n.locale == 'en' ? '' : 'text-end']" />
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="after">{{ $t('dashboard.transactions.after') }}</label>
                <v-date-picker v-model="after" :locale="$i18n.locale" :first-day-of-week="1"
                    :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control" id="after" :value="inputValue" v-on="inputEvents"
                            :class="[$i18n.locale == 'en' ? '' : 'text-end']" readonly />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="before">{{ $t('dashboard.transactions.before') }}</label>
                <v-date-picker v-model="before" :locale="$i18n.locale" :first-day-of-week="1"
                    :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control" id="before" :value="inputValue" v-on="inputEvents"
                            :class="[$i18n.locale == 'en' ? '' : 'text-end']" readonly />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="types">{{ $t('dashboard.reports.type') }}</label>
                <select id="type" class="form-select" v-model="type" :class="[$i18n.locale == 'en' ? '' : 'text-end']">
                    <option value="" selected disabled>{{ $i18n.locale == 'en' ? 'Choose a status' : 'اختر حالة' }}</option>
                    <option value="ALL">{{ $i18n.locale == 'en' ? statusFormatterEn('ALL') : statusFormatterAr('ALL') }}
                    </option>
                    <option value="DailyReport">{{ $i18n.locale == 'en' ? statusFormatterEn('DailyReport') :
                        statusFormatterAr('DailyReport') }}</option>
                    <option value="WeeklyReport">{{ $i18n.locale == 'en' ? statusFormatterEn('WeeklyReport') :
                        statusFormatterAr('WeeklyReport') }}</option>
                    <option value="MonthlyReport">{{ $i18n.locale == 'en' ? statusFormatterEn('MonthlyReport') :
                        statusFormatterAr('MonthlyReport') }}</option>
                    <option value="QuarterlyReport">{{ $i18n.locale == 'en' ? statusFormatterEn('QuarterlyReport') :
                        statusFormatterAr('QuarterlyReport') }}</option>
                    <option value="YearlyReport">{{ $i18n.locale == 'en' ? statusFormatterEn('YearlyReport') :
                        statusFormatterAr('YearlyReport') }}</option>
                    <option value="OtherReport">{{ $i18n.locale == 'en' ? statusFormatterEn('OtherReport') :
                        statusFormatterAr('OtherReport') }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center d-flex align-items-end"
            :class="[$i18n.locale == 'en' ? '' : 'flex-row-reverse text-end']">
            <div class="col-12 col-lg-10 justify-content-end d-flex align-items-end">
                <div class="col-sm-3 col-lg-2 mb-2 mb-sm-0 mt-sm-1">
                    <a class="btn btn-main btn-search" @click.prevent="resetSearch()">{{
                        $t('dashboard.transactions.reset') }}</a>
                </div>
                <div class="col-sm-3 col-lg-2 mb-2 mb-sm-0 mt-sm-1">
                    <button class="btn btn-main btn-search" @click.prevent="searchReports()">{{
                        $t('dashboard.transactions.search') }}</button>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 text-center">
                <div class="table-responsive" :class="[$i18n.locale == 'en' ? 'text-start' : 'text-end']">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index"
                                    v-for="(column, index) in columns">{{ column }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="$i18n.locale == 'en'">
                            <tr v-bind:key="index" v-for="(report, index) in reports">
                                <td>{{ new Date(report.reportDate).toLocaleDateString('en-GB') }}</td>
                                <td>{{ statusFormatterEn(report.type) }}</td>
                                <td>{{ report.name }}</td>
                                <td><router-link :to="`/${$i18n.locale}/!/report/${report.id}`">{{
                                    $t('dashboard.reports.view') }}</router-link></td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-bind:key="index" v-for="(report, index) in reports">
                                <td><router-link :to="`/${$i18n.locale}/!/report/${report.id}`">{{
                                    $t('dashboard.reports.view') }}</router-link></td>
                                <td>{{ report.name }}</td>
                                <td>{{ statusFormatterAr(report.type) }}</td>
                                <td>{{ new Date(report.reportDate).toLocaleDateString('en-GB') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="noTransactions" v-if="reports.length == 0 && reportsComplete">{{ $t('dashboard.reports.noneFound')
                }}</p>
                <p class="noTransactions" v-if="reports.length != 0 && reportsComplete">{{ $t('dashboard.reports.allLoaded')
                }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from "luxon";

export default {
    name: 'Reports',
    data() {
        return {
            columns: [
                this.$t('dashboard.reports.date'),
                this.$t('dashboard.reports.type'),
                this.$t('dashboard.reports.name'),
                this.$t('dashboard.reports.link')
            ],
            loading: false,
            search: '',
            after: '',
            before: '',
            type: '',
            limit: 20,
            offset: 0,
            reports: [],
            reportsComplete: false,
            selectAttribute: {
                highlight: {
                    style: {
                        backgroundColor: "#464338",
                    },
                },
                content: {
                    style: {
                        color: "#ffffff",
                    },
                },
            },
            reportsCount: 0,
        }
    },
    methods: {
        ...mapActions([
            'fetchReports',
            'fetchReportsCount',
        ]),
        statusFormatterEn(status) {
            switch (status) {
                case 'ALL': return 'All'
                case 'DailyReport': return 'Daily Report'
                case 'WeeklyReport': return 'Weekly Report'
                case 'MonthlyReport': return 'Monthly Report'
                case 'QuarterlyReport': return 'Quarterly Report'
                case 'YearlyReport': return 'Yearly Report'
                case 'OtherReport': return 'Other Report'
                default: return status;
            }
        },
        statusFormatterAr(status) {
            switch (status) {
                case 'ALL': return 'جميع ما يلي'
                case 'DailyReport': return 'Daily Report'
                case 'WeeklyReport': return 'Weekly Report'
                case 'MonthlyReport': return 'Monthly Report'
                case 'QuarterlyReport': return 'Quarterly Report'
                case 'YearlyReport': return 'Yearly Report'
                case 'OtherReport': return 'Other Report'
                default: return status;
            }
        },
        async getReports() {
            if (this.loading || this.reportsComplete) return;
            let payload = {};
            payload.limit = this.limit;
            payload.offset = this.offset;
            let query = {}
            if (this.search != '') {
                payload.search = this.search.trim();
                query.search = payload.search
            } else {
                query.search = ""
            }
            if (this.after != '' && this.after != null) {
                query.after = DateTime.fromJSDate(this.after).toFormat('dd-MM-yyyy');
                payload.after = DateTime.fromJSDate(this.after).toFormat('dd-MM-yyyy');
            } else {
                query.after = ""
            }
            if (this.before != '' && this.before != null) {
                query.before = DateTime.fromJSDate(this.before).toFormat('dd-MM-yyyy');
                payload.before = DateTime.fromJSDate(this.before).toFormat('dd-MM-yyyy');
            } else {
                query.before = ""
            }
            if (this.type != '') {
                query.type = this.type;
                payload.type = this.type;
            } else {
                query.type = ""
            }
            this.$router.push({ query })
            this.loading = true;
            await this.fetchReports(payload)
                .then(data => {
                    this.reports.push(...data.reports);
                    this.offset += data.reports.length;
                    if (data.reports.length < this.limit) {
                        this.reportsComplete = true;
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getReportsCount() {
            if (this.loading || this.reportsComplete) return;
            let payload = {};
            if (this.search != '') {
                payload.search = this.search;
            }
            if (this.after != '' && this.after != null) {
                payload.after = DateTime.fromJSDate(this.after).toFormat('dd-MM-yyyy');
            }
            if (this.before != '' && this.before != null) {
                payload.before = DateTime.fromJSDate(this.before).toFormat('dd-MM-yyyy');
            }
            if (this.type != '') {
                payload.type = this.type;
            }
            this.loading = true;
            await this.fetchReportsCount(payload)
                .then(data => {
                    this.reportsCount = data.count
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resetSearch() {
            let query = {}
            this.search = '';
            this.before = '';
            this.after = '';
            this.type = '';
            query.search = '';
            query.before = '';
            query.after = '';
            query.type = '';
            this.$router.push({ query })
            this.searchReports();
        },
        handleScroll() {
            const documentOffsetHeight = document.documentElement.offsetHeight;
            const documentScrollTop = Math.round(window.scrollY);
            const windowInnerHeight = window.innerHeight;
            const atBottomOfWindow = documentScrollTop + windowInnerHeight == documentOffsetHeight;
            if (atBottomOfWindow && !this.loading) {
                this.getReports();
            }
        },
        async searchReports() {
            this.offset = 0;
            this.reports = [];
            this.reportsComplete = false;
            await this.getReportsCount();
            await this.getReports();
        }
    },
    async created() {
        this.search = this.$route.query.search || ''
        this.before = this.$route.query.before || ''
        this.after = this.$route.query.after || ''
        this.type = this.$route.query.type || ''

        if (this.after != '') this.after = DateTime.fromFormat(this.after, 'dd-MM-yyyy').toJSDate();
        if (this.before != '') this.before = DateTime.fromFormat(this.before, 'dd-MM-yyyy').toJSDate();

        if (this.$i18n.locale == 'ar') {
            this.columns.reverse();
        }
        await this.getReportsCount();
        await this.getReports();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
        '$i18n.locale'() {
            if (this.$i18n.locale == 'en') {
                this.columns = [
                    this.$t('dashboard.reports.date'),
                    this.$t('dashboard.reports.type'),
                    this.$t('dashboard.reports.name'),
                    this.$t('dashboard.reports.link')
                ];
            } else {
                this.columns = [
                    this.$t('dashboard.reports.date'),
                    this.$t('dashboard.reports.type'),
                    this.$t('dashboard.reports.name'),
                    this.$t('dashboard.reports.link')
                ];
                this.columns.reverse();
            }
        }
    }
}
</script>

<style scoped>
.container {
    min-height: 110vh;
}

table.table.ar {
    text-align: end;
}

.nowrap {
    white-space: nowrap;
}

.en {
    font-family: lato-bold;
}

.ar {
    font-family: tajawal-bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.header.end {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.header button {
    margin: 0 5px;
}

button img {
    height: 12px;
}

.btn-search {
    margin: 0;
    padding: 0.375rem 1rem;
    width: 100%;
}

td {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>